import { Container, Box, Divider } from '@mui/material';
import ImpressumContent from '../elements/ImpressumContent';
import PrivacyContent from '../elements/PrivacyContent';
import '../../css/general.css';

const ImpressumPage = () => (
    <Container className="container">
        
        <Box sx={{ mt: 4 }}>
            <ImpressumContent />
        </Box>
        <Divider sx={{ my: 4 }} />
        <Box className="section">
            <PrivacyContent />
        </Box>
    </Container>
);

export default ImpressumPage;