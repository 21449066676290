import { useState } from 'react';
import { HashRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemText, Typography, Toolbar, AppBar } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IoMdMenu, IoMdArrowBack } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/system';
import ContactPage from './components/pages/ContactPage';
import ImpressumPage from './components/pages/ImpressumPage';
import HomePage from './components/pages/HomePage';
import GartenPflegePage from './components/pages/GartenPflegePage';
import SanierungsarbeitenPage from './components/pages/SanierungsarbeitenPage';

const MenuLink = styled(Link)({
  textDecoration: 'none',
  color: '#133A1B',
  fontWeight: 'bold',
  '&:hover': {
    color: '#96EFA0', // Ein hellerer Grünfarbton
  },
});

const DesktopMenu = () => (
  <AppBar position="static" color="transparent" elevation={0}>
    <Toolbar sx={{
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '25%',
        right: '25%',
        borderBottom: '2px solid rgba(19, 58, 27, 0.5)',
      }
    }}>  {/* Flexbox hinzugefügt */}
      <Typography variant="h6" style={{ marginRight: 15, color: '#133A1B' }}>
        <MenuLink to="/">Home</MenuLink>
      </Typography>
      <Typography variant="h6" style={{ marginRight: 15, color: '#133A1B' }}>
        <MenuLink to="/contact">Kontakt</MenuLink>
      </Typography>
      <Typography variant="h6" style={{ marginRight: 15, color: '#133A1B' }}>
        <MenuLink to="/impressum">Impressum</MenuLink>
      </Typography>
    </Toolbar>
  </AppBar>
);

const MobileMenu = ({ open, toggleDrawer }) => (
  <Drawer
    variant="temporary"
    open={open}
    onClose={toggleDrawer}
    sx={{
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: 240,
        bgcolor: 'white', // Farbcode für Weiß
      },
    }}
    ModalProps={{
      BackdropProps: {
        invisible: true,
      },
    }}
  >
    <List>
      <ListItem button onClick={toggleDrawer}>
        <IoMdArrowBack style={{ marginRight: '10px'}} />
        <ListItemText primary="Schließen" sx={{ fontWeight: 'bold', fontSize: '1.2em' }} />
 
      </ListItem>
      <ListItem button key="home" component={Link} to="/" onClick={toggleDrawer} sx={{ color: '#133A1B' }}> {/* Farbcode für Dunkelgrün */}
        <ListItemText primary="Home" sx={{ fontWeight: 'bold', fontSize: '1.2em' }} />
      </ListItem>
      <ListItem button key="contact" component={Link} to="/contact" onClick={toggleDrawer} sx={{ color: '#133A1B' }}>
        <ListItemText primary="Kontakt" sx={{ fontWeight: 'bold', fontSize: '1.2em' }} />
      </ListItem>
      <ListItem button key="impressum" component={Link} to="/impressum" onClick={toggleDrawer} sx={{ color: '#133A1B' }}>
        <ListItemText primary="Impressum" sx={{ fontWeight: 'bold', fontSize: '1.2em' }} />
      </ListItem>
    </List>
  </Drawer>
);



const App = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1000px)'
  });
  const isTabletOrMobile = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });

  return (
    <Router>
      <Box>
        {isTabletOrMobile && !open && <IoMdMenu size={30} onClick={toggleDrawer} style={{ position: 'fixed', top: 10, left: 10, zIndex: 1301 }} />}
        {isDesktopOrLaptop && <DesktopMenu />}
        {isTabletOrMobile && <MobileMenu open={open} toggleDrawer={toggleDrawer} />}
        <Box component="main" sx={{ flexGrow: 1, p: 3, pt: isDesktopOrLaptop ? 2 : 8 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/impressum" element={<ImpressumPage />} />
            <Route path="/gartenpflege" element={<GartenPflegePage />} />
            <Route path="/sanierungsarbeiten" element={<SanierungsarbeitenPage />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;