import { Box, Typography, Avatar } from '@mui/material';
import { FaPhone, FaEnvelope, FaInstagram, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ContactInfo = () => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 10,
    p: 3,
    position: 'relative',
    backgroundColor: '#e8f5e9', // Helles Pastellgrün
    borderRadius: '15px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    maxWidth: { xs: '100%', md: '100%' }, // Breite Anpassungen
    mx: 'auto', // Zentriert den Box-Inhalt
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '25%',
      right: '25%',
      borderTop: '4px solid #4CAF50', // Knalliger grüner Strich
    }
  }}>
    <Avatar alt="Owner" src="/IMG_1440.png" sx={{ width: 120, height: 120, mt: 0, mb: 2 }} />
    <Typography variant="h5" component="div" gutterBottom sx={{ color: '#133A1B', fontWeight: 'bold' }}>
      Kontakt
    </Typography>
    <Typography component="div" gutterBottom sx={{ color: '#133A1B', maxWidth: '80%', textAlign: 'center', marginBottom: '30px' }}>
      Bei uns stehen Sie und Ihre Wünsche im Mittelpunkt. Wir legen großen Wert auf persönlichen Service, Qualität und Zufriedenheit.
      Kontaktieren Sie uns noch heute, wir freuen uns auf Ihre Anfrage!
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%', gap: '15px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
        <FaEnvelope style={{ color: '#009E00', fontSize: 24 }} />
        <Typography variant="body1" component={Link} to="/contact" sx={{ color: '#133A1B', textDecoration: 'underline', '&:hover': { color: '#133A1B' }, wordBreak: 'break-all' }} gutterBottom>
          hausundgartenservice.unrau@gmail.com
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
        <FaPhone style={{ color: '#009E00', fontSize: 24 }} />
        <Typography variant="body1" component="a" href="tel:00491794938421" sx={{ color: '#133A1B', textDecoration: 'underline', '&:hover': { color: '#133A1B' } }} gutterBottom>
          Mobil: 0179 - 4938421
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
        <FaInstagram style={{ color: '#FD0A7D', fontSize: 24 }} />
        <Typography variant="body1" component="a" href="https://instagram.com/hausundgartenserviceunrau?igshid=MzRlODBiNWFlZA==" target="_blank" sx={{ color: '#133A1B', textDecoration: 'underline', '&:hover': { color: '#133A1B' } }} gutterBottom>
          Unser Instagram Profil
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
        <FaFacebook style={{ color: '#0866FF', fontSize: 24 }} />
        <Typography variant="body1" component="a" href="https://www.facebook.com/HausundGartenserviceArthurUnrau" target="_blank" sx={{ color: '#133A1B', textDecoration: 'underline', '&:hover': { color: '#133A1B' } }} gutterBottom>
          Unsere Facebook Seite
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default ContactInfo;