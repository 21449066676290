import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const PrivacySection = ({ title, children }) => (
    <>
        <Typography variant="h6" gutterBottom sx={{ marginTop: '30px' }}>
            {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
            {children}
        </Typography>
    </>
);

PrivacySection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default PrivacySection;