import { Box, Avatar, Typography, Divider } from '@mui/material';

const Header = ({ placeholderImage }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxWidth: { xs: '100%', md: '80%' }, mx: 'auto', mt: 4 }}>
    <Avatar alt="Logo" src={placeholderImage} sx={{ 
        minWidth: 300,
        maxWidth: 500,
        height: 'auto',
        mt: 0, mb: 2
    }} />
    <Typography variant="h5" style={{ textAlign: 'center', color:'#133A1B' }} gutterBottom>
      <b>Ihr Partner für Haus- und Gartenservices in Weilrod und Umgebung</b>
    </Typography>
    <Divider sx={{ width: '100%', mb: 2 }} />
    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginBottom: '16px', color: '#133A1B' }}>
    Willkommen!    
    </Typography>
    <Typography variant="body1" component="div" style={{ textAlign: 'center', marginBottom: '16px' }}>
    Wir sind ein dynamisches und engagiertes Unternehmen, das Ihnen hochwertige Handwerksdienstleistungen rund um Haus und Garten bietet. Gegründet von Arthur Unrau – einem ausgebildeten Tiefbaufacharbeiter mit leidenschaftlicher Hingabe zum Handwerk – stehen wir für Qualität, Zuverlässigkeit und Effizienz.
    </Typography>
    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginBottom: '16px', color: '#133A1B' }}>
      Warum Sie uns wählen sollten:
    </Typography>
    <Typography variant="body1" component="div" style={{ textAlign: 'left', marginBottom: '16px' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li><span style={{ color: '#133A1B', fontWeight: 'bold' }}>Individuelle Lösungen:</span> Wir passen unsere Dienstleistungen Ihren persönlichen Bedürfnissen an.</li>
        <li><span style={{ color: '#133A1B', fontWeight: 'bold' }}>Erfahrung und Fachwissen:</span> Profitieren Sie von jahrelanger Expertise in verschiedenen Handwerksbereichen.</li>
        <li><span style={{ color: '#133A1B', fontWeight: 'bold' }}>Regional verankert:</span> Als lokales Unternehmen sind wir stolz darauf, die Bewohner in und um Weilrod zu unterstützen.</li>
      </ul>
    </Typography>
    {/* <Typography variant="body1" component="div" style={{ textAlign: 'center' }}>
      Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam Ihr nächstes Projekt angehen!
    </Typography> */}
  </Box>
);

export default Header;