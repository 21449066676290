import React from 'react';
import { Box, Container } from '@mui/material';

const GartenpflegePage = () => (
  <Container>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
     
      <Box component="div" sx={{ maxWidth: '80%', textAlign: 'justify', marginBottom: '30px'}}>
          <h1>Coming soon</h1>
      </Box>
  </Box>
</Container>
);

export default GartenpflegePage;
