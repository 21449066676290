import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ServiceCard = ({ service, link }) => (
  <Link to={link} style={{ textDecoration: 'none' }}>
    <Card sx={{ 
      height: '100%', 
      '&:hover': {
        backgroundColor: '#f2f8f2',
        transition: 'background-color 0.3s ease'
      }
    }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ fontSize: 40, mr: 2, color: service.icon.color }}>{service.icon.element}</Box>
        <Box>
          <Typography variant="h6">{service.name}</Typography>
          <ul>
            {service.points.map((point, idx) => (
              <li key={idx}>
                <Typography variant="body1">{point}</Typography> 
              </li>
            ))}
          </ul>
        </Box>
      </CardContent>
    </Card>
  </Link>
);

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default ServiceCard;