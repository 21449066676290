import { Box, Typography } from '@mui/material';

const ImpressumContent = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
        <Typography variant="h4" component="div" gutterBottom sx={{ color: '#133A1B', fontWeight: 'bold' }}>
            Impressum
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ color: '#133A1B', maxWidth: '80%', textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}>
            Angaben gemäß § 5 TMG:
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            Inhaber: Arthur Unrau
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            Seelenberger Weg 19, 61276 Weilrod
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            Telefon: 0179 - 4938421
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            E-Mail: hausundgartenservice.unrau@gmail.com
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: 003 876 62631
        </Typography>
        <Typography variant="body1" component="div" gutterBottom sx={{ maxWidth: '80%', textAlign: 'center' }}>
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Arthur Unrau
        </Typography>
    </Box>
);

export default ImpressumContent;