import { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Divider } from '@mui/material';
import emailjs from 'emailjs-com';
import ContactDetails from '../elements/ContactDetails';
import '../../css/general.css';

const ContactPage = () => {
    const [captchaValue, setCaptchaValue] = useState('');
    const [message, setMessage] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    const YOUR_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
    const YOUR_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const YOUR_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    // const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const handleCaptchaChange = (e) => {
        setCaptchaValue(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (captchaValue === '7') {
            emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
                .then((result) => {
                    console.log(result.text);
                    alert("Email wurde erfolgreich gesendet!");
                    setEmailSent(true);
                }, (error) => {
                    console.log(error.text);
                    setMessage("Es gab einen Fehler beim Senden der Email. Bitte versuchen Sie es später erneut.");
                });
        } else {
            alert("Bitte geben Sie die korrekte Anzahl der Buchstaben im Namen 'Unrau' ein.");
        }
    }

    return (
        <Container className="container">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4"  component="div" gutterBottom sx={{ color: '#133A1B', fontWeight: 'bold', textAlign: 'center' }}>
                    Kontakt
                </Typography>
                <ContactDetails />
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box className="section">
                {!emailSent ? (
                    <form onSubmit={handleSubmit}>
                        <TextField name="from_name" label="Name" fullWidth required sx={{ mb: 2 }} />
                        <TextField name="from_email" label="E-Mail" type="email" fullWidth required sx={{ mb: 2 }} />
                        <TextField name="subject" label="Betreff" fullWidth required sx={{ mb: 2 }} />
                        <TextField name="message" label="Nachricht" multiline rows={4} fullWidth required sx={{ mb: 2 }} />
                        <TextField 
                            label="Was ergibt 3+4?"
                            onChange={handleCaptchaChange}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                        />
                        <Typography variant="body1" gutterBottom color="error">
                            {message}
                        </Typography>
                        <Button type="submit" variant="contained" color="success">Senden</Button>
                    </form>
                ) : (
                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        <Typography variant="h4" component="div" gutterBottom sx={{ color: '#133A1B', fontWeight: 'bold' }}>
                            Vielen Dank für Ihre Nachricht!
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ color: '#133A1B' }}>
                            Wir werden uns so schnell wie möglich bei Ihnen melden.
                        </Typography>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default ContactPage;