import { Box, Container, Typography, Grid } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import ServiceCard from '../elements/ServiceCard';
import ContactInfo from '../elements/ContactInfo';
import Header from '../elements/Header';
import { FaLeaf, FaHammer } from 'react-icons/fa';
import '../../css/general.css';

const placeholderImage = 'logo.png';

const services = [
  {
    name: 'Gartengestaltung',
    icon: { element: <FaLeaf />, color: '#4CAF50' },
    points: ['Baumpflege / Fällung', 'Natursteinarbeiten', 'Außenanlagen', 'Gartenpflege']
  },
  {
    name: 'Sanierungsarbeiten',
    icon: { element: <FaHammer />, color: '#FFC107' },
    points: ['Bodenbeläge jeglicher Art', 'Modernisierungsarbeiten', 'Montagearbeiten', 'Malerarbeiten']
  },
];

const HomePage = () => (
  <Container className="container">
    <Helmet>
      <title>Haus- und Gartenservice Arthur Unrau - Professionelle Gartengestaltung und Sanierungsarbeiten in Weilrod</title>
      <meta name="description" content="Haus- und Gartenservice Arthur Unrau bietet qualitativ hochwertige Gartengestaltung und Sanierungsarbeiten in Weilrod. Erfahren Sie mehr über unsere professionellen Dienstleistungen und kontaktieren Sie uns für individuelle Lösungen."/>
    </Helmet>
    <Header placeholderImage={placeholderImage} />
    <br></br>
    <Box className="section">    
      <Typography variant="h5" component="div" textAlign="center" gutterBottom sx={{ color: '#133A1B', fontWeight: 'bold' }}>
        Unsere Dienstleistungen
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <ServiceCard service={service} link={index === 0 ? "/gartenpflege" : "/sanierungsarbeiten"} />
          </Grid>
        ))}
      </Grid>
    </Box>
    <Box className="section">
      <ContactInfo />
    </Box>
  </Container>
);

export default HomePage;