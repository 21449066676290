import { Box, Typography } from '@mui/material';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';

const ContactDetails = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
        <Typography variant="body1" component="div" gutterBottom sx={{ color: '#133A1B', maxWidth: '80%', textAlign: 'center', marginBottom: '30px' }}>
            Für alle Fragen und Anfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FaMapMarkerAlt style={{ color: '#009E00', marginRight: 10 }} />
            <Typography variant="body1" gutterBottom sx={{fontWeight: 'bold' }}>
                Arthur Unrau, Seelenberger Weg 19, 61276 Weilrod
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FaEnvelope style={{ color: '#009E00', marginRight: 10 }} />
            <Typography variant="body1" gutterBottom sx={{fontWeight: 'bold' }}>
                hausundgartenservice.unrau@gmail.com
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FaPhone style={{ color: '#009E00', marginRight: 10 }} />
            <Typography variant="body1" gutterBottom sx={{fontWeight: 'bold' }}>
                0179 - 4938421
            </Typography>
        </Box>
    </Box>
);

export default ContactDetails;